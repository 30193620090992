export default {
    init: (Popin) => {
        
        // Popin comments
        /////////////////////////////////
        const popinEl = document.querySelector('.js-popin-share')
        if (popinEl) {

            const popinInstance = new Popin(popinEl, {
                openBtnCSSSelector: '.js-popin-share-open',
                closeBtnCSSSelector: '.js-popin-share-close',
                popinBackCSSSelector: '.js-popin-share-back',
                popinContentCSSSelector: '.js-popin-share-content'
            })

		}

        // Copy link
        /////////////////////////////////
        const copyLink = document.querySelector('.js-share-copy')

        if (copyLink) {
            copyLink.addEventListener('click', function(e){
                e.preventDefault()
                navigator.clipboard.writeText(window.location.href);
            })
        }
    }
}