export default {
	init: function(utils, Popin){

        this.handleVideo(utils, Popin)
        
        const body = document.querySelector('body')
        body.addEventListener('dom:updated', () => {
            this.handleVideo(utils, Popin)
        })

    },
    handleVideo: function(utils, Popin){
        
        const popinVideoEl = document.querySelector('.js-popin-video')
        if (popinVideoEl) {

            const popinVideoInstance = new Popin(popinVideoEl, {
                openBtnCSSSelector: '.js-popin-video-open',
                closeBtnCSSSelector: '.js-popin-video-close',
                popinBackCSSSelector: '.js-popin-video-back',
                popinContentCSSSelector: '.js-popin-video-content'
            })

            const openBtns = document.querySelectorAll('.js-popin-video-open');
            let currentVideoType = null;
            let videoYTSrc = null;
            let iframeVideo = null;

            if (openBtns.length > 0) {
                openBtns.forEach(button => {
                    
                    const videoUrl = button.dataset.videoId;
                    const videoType = button.dataset.videoType ? button.dataset.videoType : 'steamlike'

                    if (videoUrl) {
                        button.addEventListener('mouseenter', () => {

                            iframeVideo = popinVideoEl.querySelector(`.js-popin-video-iframe-${videoType}`);
                            iframeVideo.setAttribute('src', videoUrl);

                            const currentActive = document.querySelector('.js-popin-video-iframe.--active');
                            if (currentActive) {
                                currentActive.classList.remove('--active')
                            }
                            
                            iframeVideo.classList.add('--active');
                        })
                    }
                })
            }

            popinVideoInstance.on('closed', () => {
                if (currentVideoType == "streamlike") {
                    iframeVideo.contentWindow.postMessage(`["pause"]`, '*')
                } else {
                    videoYTSrc = videoYTSrc.slice(0, -11)
                    iframeVideo.src = videoYTSrc
                }
                currentVideoType = null;
                utils.enableScroll()
            })

            popinVideoInstance.on('opened', (event) => {
                const videoClickedType = event.detail.clickedEl.dataset.videoType;
                currentVideoType = videoClickedType;
                if (videoClickedType == "streamlike") {
                    setTimeout(() => {
                        iframeVideo.contentWindow.postMessage(`["play"]`, '*')
                    }, 1000);
                } else {
                    setTimeout(() => {
                        videoYTSrc = iframeVideo.src += '&autoplay=1';
                    }, 500);
                }
            })
            
        }
    }
}