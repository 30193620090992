export default {
	url: new URL(location),
	facetAjax: null,

	init: function(utils, gsap) {
		this.animateFacetLevel1(gsap);
		this.animateFacetLevel2(gsap);

		this.bindFiltering();
		this.bindFacetCard();
		this.bindResetFilter();

		this.updateAllFacetCount();
		this.addFacetCardToSearchParams();
		this.historyPushState();
	},

	animateFacetLevel1: function(gsap) {
		// Ignore facet with "r" key, it is not in dropdown
		document.querySelectorAll('.js-facet:not([data-facet-key=r])').forEach((facet, index) => {
			const facetHead = facet.querySelector('.js-facet-head');
			if (!facetHead) {
				// equivalent to 'continue'
				return;
			}

			const facetList = facet.querySelector('.js-facet-list');
			const facetListItems = facet.querySelectorAll('.js-facet-list > .js-facet-list-item');

			const level1Tl = gsap.timeline({reversed: true, paused: true });
			level1Tl
				.from(facetList, { autoAlpha: 0, paddingTop: 0, paddingBottom: 0, duration: 0.3, ease: "power2.inOut" })
				.fromTo(facetList, { height: 0 }, { height: 'auto', duration: 0.3, ease: "power2.inOut" }, '<')
				.from(facetListItems, { autoAlpha: 0, y: 30, duration: 0.3, stagger: 0.03, ease: "power2.out" }, '<0.1')

			// By default the first item is opened
			if (index === 0) {
				level1Tl.play();
			}

			facetHead.addEventListener('click', function(e){
				facetHead.classList.toggle('--opened');
				level1Tl.reversed() ? level1Tl.play() : level1Tl.reverse();
			})
		})
	},

	animateFacetLevel2: function(gsap) {
		document.querySelectorAll('.js-facet-list-item').forEach(facetListItem => {
			const childToggle = facetListItem.querySelector('.js-facet-list-child-toggle');
			if (childToggle !== null)  {
				const childList = facetListItem.querySelector('.js-facet-list-child-list');
				const childListItems = facetListItem.querySelectorAll('.js-facet-list-child-list > .js-facet-list-child-list-item');

				const level2Tl = gsap.timeline({reversed: true, paused: true });
				level2Tl
					.from(childList, { autoAlpha: 0, paddingTop: 0, paddingBottom: 0, duration: 0.3, ease: "power2.inOut" })
					.fromTo(childList, { height: 0 }, { height: 'auto', duration: 0.3, ease: "power2.inOut" }, '<')
					.from(childListItems, { autoAlpha: 0, y: 30, duration: 0.3, stagger: 0.03, ease: "power2.out" }, '<0.1')

				childToggle.addEventListener('click', function(e){
					childToggle.classList.toggle('--opened')
					level2Tl.reversed() ? level2Tl.play() : level2Tl.reverse();
				})
			}
		})
	},

	bindFiltering: function() {
		document.querySelectorAll('.js-facetFilter').forEach(facetFilter => {
			facetFilter.addEventListener('input', e => {
				this.buildUrlWithActiveFacets();
				this.ajaxSubmitFilter();
				this.updateFacetCount(e.currentTarget.closest('.js-facet'));
			})
		})
	},

	bindFacetCard: function() {
		const AllFacetCard = document.querySelectorAll('.js-facetCard')
		AllFacetCard.forEach(facetCard => {
			facetCard.addEventListener('click', e => {
				const card = e.currentTarget;

				// Update css active effect
				AllFacetCard.forEach(fc => {
					fc.classList.remove('--selected')
				})
				card.classList.add('--selected')

				this.buildUrlWithActiveFacets();

				// Submit ajax
				this.ajaxSubmitFilter();
			})
		})
	},

	addFacetCardToSearchParams: function(facetCard) {
		facetCard = facetCard || document.querySelector('.js-facetCard.--selected')

		// Build URL param
		const card_key = facetCard.getAttribute('data-key');
		const card_value = facetCard.getAttribute('data-value');
		if (card_value.length > 0) {
			this.url.searchParams.set(card_key, encodeURI(card_value));
		} else {
			this.url.searchParams.delete(card_key);
		}
	},

	bindResetFilter: function() {
		document.querySelector('.js-facetFilter-reset').addEventListener('click', e => {
			document.querySelectorAll('.js-facetFilter').forEach(facetFilter => {
				if (facetFilter.tagName === 'SELECT') {
					facetFilter.selectedIndex = -1;
				} else if (facetFilter.type === 'checkbox' || facetFilter.type === 'radio') {
					facetFilter.checked = false;
				} else {
					facetFilter.value = '';
				}
			})

			// Clear url of all params
			this.url.search = '';
			// Restore only the facet card, it is mandatory
			this.addFacetCardToSearchParams();
			this.historyPushState();

			// Update counters
			this.updateAllFacetCount();

			this.ajaxSubmitFilter();
		})
	},

	updateAllFacetCount: function() {
		document.querySelectorAll('.js-facet').forEach(facet => {
			this.updateFacetCount(facet)
		})
	},
	
	updateFacetCount: function(facet) {
		const facetCounter = facet.querySelector('.js-facet-counter');
		if (!facetCounter) {
			// equivalent to 'continue'
			return;
		}

		const facet_type = facet.getAttribute('data-facet-type');
		let counter;

		if (facet_type === 'select') {
			let value = facet.querySelector('.js-facetFilter').value;
			counter = value.length;
		} else {
			let checkedFacets = facet.querySelectorAll('.js-facetFilter:checked');
			counter = checkedFacets.length;
		}

		facetCounter.innerText = counter;
	},

	buildUrlWithActiveFacets: function() {
		// Classic facets
		document.querySelectorAll('.js-facet').forEach(facet => {
			const facet_key = facet.getAttribute('data-facet-key');
			const facet_type = facet.getAttribute('data-facet-type');
			let facet_value;

			if (facet_type === 'select') {
				facet_value = [];
				const selectedOptions = facet.querySelector('.js-facetFilter').selectedOptions;
				Array.from(selectedOptions).forEach(option => {
					facet_value.push(option.value);
				})
				facet_value = facet_value.join(',');
			} else if (facet_type === 'text') {
				facet_value = facet.querySelector('.js-facetFilter').value;
				// Force empty value if smaller than 3 to avoid small words
				if (facet_value.length < 3) {
					facet_value = '';
				}
			} else {
				facet_value = [];
				facet.querySelectorAll('.js-facetFilter:checked').forEach(facetFilter => {
					facet_value.push(facetFilter.value);
				})
				facet_value = facet_value.join(',');
			}

			if (facet_value.length > 0) {
				this.url.searchParams.set(facet_key, encodeURI(facet_value));
			} else {
				this.url.searchParams.delete(facet_key);
			}
		})

		// Facet cards (For now we only have 1 facet card type on a page
		// Maybe do something else if we have a page with differents facet cards
		const facetCard = document.querySelector('.js-facetCard.--selected')
		// Build URL param
		const card_key = facetCard.getAttribute('data-key');
		const card_value = facetCard.getAttribute('data-value');
		if (card_value.length > 0) {
			this.url.searchParams.set(card_key, encodeURI(card_value));
		} else {
			this.url.searchParams.delete(card_key);
		}

		this.addFacetCardToSearchParams();

		this.historyPushState();
	},

	historyPushState: function() {
		history.pushState({}, "", this.url);
	},

	ajaxSubmitFilter: function() {
		// If doing ajax request, abort it !
		if (this.facetAjax) {
			this.facetAjax.abort();
		}

		const form_data = new FormData();
		form_data.append('action', 'program_list');
		form_data.append('url', this.url);

		document.querySelectorAll('.js-facet-hidden').forEach(facet => {
			// must encodeURI because with complex php array we have annoying backslashes...
			form_data.append(facet.getAttribute('name'), encodeURI(facet.value));
		})

		this.facetAjax = $.ajax({
				url: wordpress_vars.ajax_url,
				type: 'POST',
				contentType: false,
				processData: false,
				data: form_data,
				complete: () => {
					this.facetAjax = null;
				}
			})
			.done(response => {
				document.querySelector('.js-list').innerHTML = response;
			})
			.fail(() => {
				console.log("ajax failed");
			})
	},
}
